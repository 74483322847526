/*------------- Ajout de polices ---------------*/
@font-face {
  font-family: "titre";
  src: url("./polices/PermanentMarker-Regular.ttf");
}
@font-face {
  font-family: "footer";
  src: url("./polices/ConcertOne-Regular.ttf");
}
@font-face {
  font-family: "article";
  src: url("./polices/Lato-Regular.ttf");
}

.colorBox {
  background-color: #C4DFE6 ;
}

.colorBack {
  background-color: #66A5AD ;
}

.image {
  height: 70px;
  width: auto;
}

.monItem:hover {
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 5px 5px 5px grey;
}

.prez {
  font-family: titre;
  text-align: center;
}